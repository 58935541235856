import React, { useEffect, useState } from "react";
import styles from "../../Product.module.scss";
import { FormGroup, TextArea, TextInput } from "src/components";
import TogglableArea from "../../TogglableArea";
import TogglableTabs from "../../ToggleTabs";
import { ProductType } from "../../Product.types";
import SingleUploader from "../../SingleUploader";
import MultipleUploader from "../../MultipleUploader";

const DefineProduct = ({
  product,
  setProduct,

  // files:
  file,
  setFile,

  thumbnail,
  setThumbnail,

  images,
  setImages,

  id,

  errors,
}: {
  product: ProductType;
  setProduct: React.Dispatch<React.SetStateAction<ProductType>>;
  file: File | null;
  setFile: React.Dispatch<React.SetStateAction<File | null>>;
  id?: string;

  thumbnail: File | null;
  setThumbnail: React.Dispatch<React.SetStateAction<File | null>>;
  images: File[] | null;
  setImages: React.Dispatch<React.SetStateAction<File[] | null>>;

  errors: any;
}) => {
  const [prodType, setProdType] = useState<string>(
    product.isFile ? "file" : "url"
  );
  const [priceType, setPriceType] = useState<string>(
    !product._id
      ? "regular"
      : product.payWant
      ? "payWant"
      : product.price === 0
      ? "free"
      : "regular"
  );

  const onChange = (key: string, value: string) => {
    setProduct({ ...product, [key]: value });
  };

  const onTypeChange = (value: string) => {
    setProdType(value);
    setProduct({ ...product, isFile: value === "file" });
  };

  const onPriceChange = (value: string) => {
    setPriceType(value);
    if (value === "free") {
      setProduct({ ...product, price: 0, payWant: false });
    } else if (value === "payWant") {
      setProduct({
        ...product,
        price: 0,
        minPrice: 9.99,
        maxPrice: 29.99,
        payWant: true,
      });
    } else {
      setProduct({ ...product, price: 9.99, payWant: false });
    }
  };

  const onFileChange = (file: File | null) => {
    setFile(file);
  };

  const onThumbnailChange = (file: File | null) => {
    setThumbnail(file);
  };

  const onImagesChange = (files: File[] | null) => {
    setImages(files);
  };

  return (
    <div className={styles.step}>
      <h1>Define your product</h1>
      <p className={styles.desc}>
        Add product info, pricing structure, thumbnail and images.
      </p>
      <div className={styles.form}>
        <FormGroup label="Title" error={errors.name} required>
          <TextInput
            onChange={(e: any) => onChange("name", e.target.value)}
            value={product.name}
            name="name"
            placeholder="Enter product title"
            error={errors.name}
          />
        </FormGroup>
        <FormGroup label="Description" error={errors.description}>
          <TextArea
            onChange={(e: any) => onChange("subtitle", e.target.value)}
            value={product.subtitle}
            name="subtitle"
            placeholder="Enter product description"
            error={errors.subtitle}
          />
        </FormGroup>
        <div id="url">
          <TogglableArea
            title="Product type"
            subTitle="Choose the type of your product, whether as a file or as a URL."
            required
          >
            <TogglableTabs
              items={[
                {
                  id: "file",
                  title: "File",
                  subTitle: "People will download your product",
                },
                {
                  id: "url",
                  title: "URL",
                  subTitle: "People will get access to your URL",
                },
                {
                  id: "course",
                  title: "Course",
                  subTitle: "Host your course and let your students enroll",
                  soon: true,
                },
              ]}
              onChange={(value: string) => onTypeChange(value)}
              value={prodType}
            />
            {prodType === "file" ? (
              <SingleUploader
                onChange={onFileChange}
                value={file}
                text="Maximum file size: 200MB"
                error={errors.file}
              />
            ) : (
              <FormGroup label="URL" required error={errors.url}>
                <TextInput
                  onChange={(e: any) => onChange("url", e.target.value)}
                  value={product.url}
                  error={errors.url}
                  name="url"
                  placeholder="Enter product URL"
                />
              </FormGroup>
            )}
          </TogglableArea>
        </div>
        <div id="price">
          <TogglableArea
            title="Pricing"
            subTitle="Choose a pricing structure that’s suitable for you and price your product accordingly."
          >
            <TogglableTabs
              items={[
                {
                  id: "regular",
                  title: "Regular price",
                  subTitle: "Charge a regular fee",
                },
                {
                  id: "payWant",
                  title: "Pay what you want",
                  subTitle: "People will set the price",
                },
                {
                  id: "free",
                  title: "Lead magnet",
                  subTitle: "Give it away for free",
                },
              ]}
              onChange={(value: string) => onPriceChange(value)}
              value={priceType}
            />
            {priceType === "regular" ? (
              <FormGroup label="Price" error={errors.price} required>
                <TextInput
                  onChange={(e: any) => onChange("price", e.target.value)}
                  value={product.price}
                  error={errors.price}
                  name="price"
                  placeholder="0"
                  isCurrency
                  type="number"
                />
              </FormGroup>
            ) : priceType === "payWant" ? (
              <div className={styles.formGrid}>
                <FormGroup
                  label="Minimum price"
                  error={errors.minPrice}
                  required
                >
                  <TextInput
                    onChange={(e: any) => onChange("minPrice", e.target.value)}
                    value={product.minPrice || 0}
                    error={errors.minPrice}
                    name="minPrice"
                    placeholder="0"
                    isCurrency
                    type="number"
                  />
                </FormGroup>
                <FormGroup label="Suggested price">
                  <TextInput
                    onChange={(e: any) => onChange("maxPrice", e.target.value)}
                    value={product.maxPrice || 0}
                    name="maxPrice"
                    placeholder="0"
                    isCurrency
                    type="number"
                  />
                </FormGroup>
              </div>
            ) : (
              <FormGroup label="Price">
                <TextInput
                  onChange={(e: any) => onChange("price", e.target.value)}
                  value={product.price}
                  name="price"
                  placeholder="0"
                  isCurrency
                  type="number"
                  disabled
                />
              </FormGroup>
            )}
          </TogglableArea>
        </div>

        <TogglableArea
          title="Thumbnail"
          subTitle="Upload a thumbnail of your product. Used in your profile page,
          checkout, and email."
        >
          <SingleUploader
            onChange={onThumbnailChange}
            value={thumbnail}
            text="Supports JPEG, PNG, GIF. Maximum file size: 4MB."
            type="image"
          />
        </TogglableArea>
        {/* 
        <TogglableArea
          title="Images"
          subTitle="Upload images of your product. They will be shown in your product page as carousel."
        >
          <MultipleUploader
            onChange={onImagesChange}
            value={images}
            text="Supports JPEG, PNG, GIF. Maximum file size: 4MB."
            type="image"
          />
        </TogglableArea> */}
        {/* 
        <TogglableArea
          title="FAQs"
          subTitle="Add frequently asked questions and answers to your product."
        >
          
        </TogglableArea> */}
      </div>
    </div>
  );
};

export default DefineProduct;
