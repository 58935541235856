import React, { useEffect, useState } from "react";
import { HiOutlineExternalLink } from "react-icons/hi";
import { FormGroup, Select, TipTap } from "src/components";

import styles from "src/pages/Product_/Product.module.scss";
import TogglableArea from "src/pages/Product_/TogglableArea";
import Axios from "src/services/api";

const CheckoutPage = ({ product, setProduct, errors }: any) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [refunds, setRefunds] = useState<any[]>([]);
  const onChange = (key: string, value: any) => {
    setProduct({ ...product, [key]: value });
  };

  const [refresh, setRefresh] = useState<boolean>(false);

  useEffect(() => {
    Axios.get("/refunds").then((response) => {
      setRefunds(response.data);
      setLoading(false);
      onChange("refundPolicy", null);
      onChange("refundPolicy", product.refundPolicy);
    });
  }, [refresh]);
  return (
    <div className={styles.step}>
      <h1>Customize the checkout page</h1>
      <p className={styles.desc}>
        Write a description to be displayed on the left side of the Checkout
        page.
      </p>
      <div className={styles.form}>
        <FormGroup label="Checkout copy">
          <TipTap
            content={product.checkoutText}
            onChange={(content: string) => onChange("checkoutText", content)}
          />
        </FormGroup>
        <TogglableArea
          title="Refund policy"
          subTitle="Select a refund policy for this product, to be displayed on the right side of the Checkout page."
        >
          {loading ? (
            <div className={styles.loadingState}>Loading...</div>
          ) : (
            <>
              <FormGroup
                label="Refund policy"
                action={
                  <div
                    className={styles.formAction}
                    onClick={() => {
                      setLoading(true);
                      setRefresh(!refresh);
                    }}
                  >
                    Refresh
                  </div>
                }
              >
                <Select
                  options={[
                    {
                      name: "No refund policy",
                      _id: "",
                    },
                  ]
                    .concat(refunds)
                    .map((refund) => ({
                      label: refund.name,
                      value: refund._id,
                    }))}
                  onChange={(value) => onChange("refundPolicy", value)}
                  selectedValue={product.refundPolicy || ""}
                />
              </FormGroup>
              {product.refundPolicy && (
                <FormGroup label="" dummy>
                  <div className={styles.refundOverview}>
                    <div className={styles.refundText}>
                      <div>
                        {
                          refunds.find(
                            (refund) => refund._id === product.refundPolicy
                          ).name
                        }
                      </div>
                      <div>
                        Refund period:{" "}
                        {
                          refunds.find(
                            (refund) => refund._id === product.refundPolicy
                          ).period
                        }{" "}
                        {
                          refunds.find(
                            (refund) => refund._id === product.refundPolicy
                          ).periodType
                        }
                      </div>
                    </div>
                    <a
                      className={styles.openInNew}
                      href={`/refunds/${product.refundPolicy}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <HiOutlineExternalLink size={18} />
                      <span>Edit refund policy</span>
                    </a>
                  </div>
                </FormGroup>
              )}
            </>
          )}
        </TogglableArea>
      </div>
    </div>
  );
};

export default CheckoutPage;
