import React, { useState, useEffect, useRef, useMemo } from "react";
import styles from "./Sidebar.module.scss";
import {
  Home,
  Users,
  ShoppingCart,
  Box,
  DollarSign,
  MessageSquare,
  ArrowUpCircle,
  Settings,
  LogOut,
  HelpCircle,
  FolderPlus,
} from "react-feather";
import { useAuth0 } from "@auth0/auth0-react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { usePopperTooltip } from "react-popper-tooltip";
import "react-popper-tooltip/dist/styles.css";

import {
  MdChevronLeft,
  MdChevronRight,
  MdOutlineMoreVert,
} from "react-icons/md";
import "remixicon/fonts/remixicon.css";
import { Button } from "../Button";
import Axios from "src/services/api";
import { BsBank, BsPatchCheckFill } from "react-icons/bs";
import { HiOutlineReceiptRefund } from "react-icons/hi";
import toast from "react-hot-toast";
import { TbDiscount2 } from "react-icons/tb";
import { makeCdnUrl } from "src/services/cdn";

const Sidebar = ({ sidebarOpen, setSidebarOpen }: any) => {
  const { user, logout } = useAuth0();

  const [open, setOpen] = useState(false);
  const [boughtProducts, setBoughtProducts] = useState<any>([]);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const regex = /^\/products\/[0-9a-zA-Z]+$/;
    if (regex.test(location.pathname)) {
      setSidebarOpen(false);
    }
  }, [location.pathname]);

  const isActive = (route: string) => {
    const isDashboard = route === "/" && location.pathname === "/";
    if (isDashboard) {
      return location.pathname === route;
    } else {
      if (route === "/") {
        return false;
      }
      return location.pathname.includes(route);
    }
  };

  const ref = useRef<HTMLDivElement | null>(null);

  const [stripeAccount, setStripeAccount] = React.useState<any>(null);

  const accessToken = localStorage.getItem("accessToken");

  useEffect(() => {
    const getLocalStorage = localStorage.getItem("stripeAccount");
    if (!getLocalStorage) {
      Axios.get("/users/stripe-account", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }).then((res) => {
        setStripeAccount(res.data);
        // set local storage
        localStorage.setItem("stripeAccount", JSON.stringify(res.data));
      });
    } else {
      setStripeAccount(JSON.parse(getLocalStorage));
    }
  }, []);

  useEffect(() => {
    Axios.get("/bought-products", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }).then((res) => {
      setBoughtProducts(res.data);
    });
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // watch for screen size changes
    window.addEventListener("resize", () => {
      setSidebarOpen(window.innerWidth > 1200);
    });
  }, [window.innerWidth]);

  const me = useMemo(
    () => localStorage.getItem("me") && JSON.parse(localStorage.getItem("me")!),
    [localStorage.getItem("me")]
  );

  if (!user || !me) {
    return null;
  }

  const primaryMenuItems = [
    {
      name: "Dashboard",
      icon: (size = 18) => <Home className={styles.icon} size={size} />,
      to: "/",
    },
    {
      name: "Customers",
      icon: (size = 18) => <Users className={styles.icon} size={size} />,
      to: "/customers",
    },
    {
      name: "Orders",
      icon: (size = 18) => <ShoppingCart className={styles.icon} size={size} />,
      to: "/orders",
    },
    {
      name: "Products",
      icon: (size = 18) => <Box className={styles.icon} size={size} />,
      to: "/products",
    },
    {
      name: "Discounts",
      icon: (size = 18) => <TbDiscount2 className={styles.icon} size={size} />,
      to: "/discounts",
    },
    {
      name: "Upsells",
      icon: (size = 18) => (
        <ArrowUpCircle className={styles.icon} size={size} />
      ),
      to: "/upsells",
    },
    {
      name: "Reviews",
      icon: (size = 18) => (
        <MessageSquare className={styles.icon} size={size} />
      ),
      to: "/reviews",
    },
    {
      name: "Payouts",
      icon: (size = 18) => <DollarSign className={styles.icon} size={size} />,
      to: "/payouts",
    },
    {
      name: "Refund policies",
      icon: (size = 18) => (
        <HiOutlineReceiptRefund className={styles.icon} size={size} />
      ),
      to: "/refunds",
    },
  ];

  // const secondaryMenuItems = [
  //   {
  //     name: "Affiliates",
  //     icon: <UserCheck className={styles.icon} size={18} />,
  //     to: "/affiliates",
  //     soon: true,
  //   },
  //   {
  //     name: "Affiliated Products",
  //     icon: <Package className={styles.icon} size={18} />,
  //     to: "/affiliated-products",
  //     soon: true,
  //   },
  // ];

  const secondaryMenuItems = [
    {
      name: "Bought Products",
      icon: (size = 18) => <FolderPlus className={styles.icon} size={size} />,
      to: "/bought-products",
    },
  ];

  const hasBankAccount =
    stripeAccount && stripeAccount?.external_accounts?.data?.length > 0;
  const hasPayPal = !!me?.merchantId && !!me?.merchantIdInPayPal;

  const hasAlerts =
    stripeAccount?.requirements?.currently_due?.length > 0 ||
    stripeAccount?.requirements?.past_due?.length > 0;

  const connectStripe = async () => {
    try {
      navigate("/profile/payments");
    } catch (error) {
      console.log(error);
    }
  };

  const createLogin = async () => {
    try {
      toast.promise(
        Axios.get("/users/create-login-link").then((response) => {
          if (response.data.url) {
            // window.open(response.data.url, "_blank");
            const a = document.createElement("a");
            a.href = response.data.url;
            a.rel = "noopener noreferrer";
            a.click();
          } else {
            try {
              throw new Error("Failed to create login link.");
            } catch (error) {}
          }
        }),
        {
          loading: "Loading...",
          success: "Loaded!",
          error: "Error while loading link.",
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  return sidebarOpen ? (
    <div className={styles.sidebar}>
      <Link to="/">
        <div className={styles.logo}>
          {/* Pocketsflow */}
          <img
            className={styles.logoImageText}
            src={process.env.PUBLIC_URL + "/pf-logo-text.svg"}
            alt="Pocketsflow"
          />
          <div
            className={styles.arrow}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setSidebarOpen(false);
            }}
          >
            <MdChevronLeft className={styles.chevronLeft} size={20} />
            <MdChevronRight className={styles.chevronRight} size={20} />
          </div>
        </div>
      </Link>
      <div className={styles.divider}></div>
      <div className={styles.sidebarLinks}>
        {!me?.hideGuide && (
          <Link
            className={`${styles.menuItem} ${
              isActive("/guide") && styles.activeItem
            } ${styles.guide}`}
            to="/guide"
          >
            <BsPatchCheckFill className={styles.icon} size={18} />
            Guided Setup
          </Link>
        )}
        {primaryMenuItems.map((item, index) => (
          <Link
            key={index}
            className={`${styles.menuItem} ${
              isActive(item.to) && styles.activeItem
            }`}
            to={item.to}
          >
            {item.icon()}
            {item.name}
          </Link>
        ))}
        <br></br>
        <div className={styles.divider}></div>
        {secondaryMenuItems.map((item, index) => (
          <Link
            key={index}
            className={`${styles.menuItem} ${
              isActive(item.to) && styles.activeItem
            }`}
            to={item.to}
          >
            {item.icon()}
            {item.name}
          </Link>
        ))}
        <br />
        <br />
        <div className={styles.alertsContainer}>
          {!hasBankAccount && !hasPayPal && (
            <div
              className={styles.stripeAlert + " " + styles.warning}
              onClick={connectStripe}
            >
              <div className={styles.stripeAlertText}>
                <div className={styles.stripeAlertTitle}>
                  Connect a payment gateway
                </div>
                <div className={styles.stripeAlertDescription}>
                  You need to connect either a Bank account or PayPal to receive
                  payments, and publish your products to start selling.
                </div>
              </div>
              <Button variant="danger" text="Connect a gateway" />
            </div>
          )}
          {hasAlerts && hasBankAccount && (
            <div
              className={styles.stripeAlert + " " + styles.warning}
              onClick={createLogin}
            >
              <div className={styles.stripeAlertText}>
                <div className={styles.stripeAlertTitle}>
                  Verification required
                </div>
                <div className={styles.stripeAlertDescription}>
                  Verification is needed for your bank account to continue
                  receiving payments securely and ensure your finances are
                  secure.
                </div>
              </div>
              <Button variant="danger" text="Verify your account" />
            </div>
          )}
          {/* {!user?.email_verified && (
        <div className={styles.stripeAlert}>
          <div className={styles.stripeAlertText}>
            <div className={styles.stripeAlertTitle}>
              Verify your email address
            </div>
            <div className={styles.stripeAlertDescription}>
              You need to verify your email address to prove that you own it and
              start selling.
            </div>
          </div>
          <Button
            text="Connect a Bank Account"
            onClick={() => {
              navigate("/profile/payments");
            }}
          />
        </div>
      )} */}
        </div>
      </div>
      <div
        className={styles.profileContainer}
        ref={ref}
        onClick={() => {
          setOpen(!open);
        }}
      >
        {open && (
          <span className={`${styles.profile}`} ref={ref}>
            <Link className={styles.profileLink} to="/profile">
              <Settings className={styles.icon} size={18} />

              <div>Settings</div>
            </Link>
            <div className={styles.divider}></div>
            <div
              className={styles.profileLink}
              onClick={() => {
                localStorage.removeItem("accessToken");
                logout({
                  logoutParams: {
                    returnTo: window.location.origin,
                  },
                });
              }}
            >
              <LogOut className={styles.icon} size={18} />
              <div>Logout</div>
            </div>
          </span>
        )}
      </div>
      <div className={styles.userContainer} onClick={() => setOpen(!open)}>
        <div className={styles.userImage}>
          <img src={makeCdnUrl(me.picture)} alt="User" />
        </div>
        <span className={styles.userName}>
          {me.firstName || me.name} {me.lastName}
        </span>
      </div>
    </div>
  ) : (
    <div className={styles.sidebar + " " + styles.closedSidebar}>
      <div className={styles.hoverer} />
      <Link to="/">
        <div className={styles.logo}>
          <img
            className={styles.logoImage}
            src={process.env.PUBLIC_URL + "/pf-logo.svg"}
            alt="Pocketsflow"
          />
          <div
            className={styles.arrow}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setSidebarOpen(!open);
            }}
          >
            <MdChevronLeft className={styles.chevronLeft} size={20} />
            <MdChevronRight className={styles.chevronRight} size={20} />
          </div>
        </div>
      </Link>
      <div className={styles.divider}></div>
      <div className={styles.sidebarLinks}>
        {!me?.hideGuide && (
          <Link
            className={`${styles.menuItem} ${
              isActive("/guide") && styles.activeItem
            } ${styles.guide}`}
            to="/guide"
          >
            <BsPatchCheckFill className={styles.icon} size={24} />
          </Link>
        )}
        {primaryMenuItems.map((item, index) => (
          <LinkItem item={item} index={index} isActive={isActive} />
        ))}
        <div className={styles.divider}></div>
        {secondaryMenuItems.map((item, index) => (
          <LinkItem item={item} index={index} isActive={isActive} />
        ))}
        <br />
        <br />
        <div className={styles.alertsContainer}>
          {!hasBankAccount && !hasPayPal && (
            <div
              className={styles.stripeAlert + " " + styles.warning}
              onClick={connectStripe}
            >
              <BsBank size={18} className={styles.bankIcon} />
            </div>
          )}
          <Link
            className={`${styles.menuItem} ${
              isActive("/help") && styles.activeItem
            }`}
            to="/help"
          >
            <HelpCircle className={styles.icon} size={18} />
          </Link>
        </div>
      </div>
      <div className={styles.divider}></div>
      {open && (
        <span className={`${styles.profile}`} ref={ref}>
          <Link className={styles.profileLink} to="/profile">
            <Settings className={styles.icon} size={18} />
            <div>Settings</div>
          </Link>
          <div className={styles.divider}></div>
          <div
            className={styles.profileLink}
            onClick={() => {
              localStorage.removeItem("accessToken");
              logout({
                logoutParams: {
                  returnTo: window.location.origin,
                },
              });
            }}
          >
            <LogOut className={styles.icon} size={18} />
            <div>Logout</div>
          </div>
        </span>
      )}
      <div className={styles.userContainer} onClick={() => setOpen(!open)}>
        <div className={styles.userImage}>
          <img src={me.picture} alt="User" />
        </div>
      </div>
      {/* </Link> */}
    </div>
  );
};

const LinkItem = ({ item, index, isActive }: any) => {
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({
    placement: "right",
    trigger: "hover",
    offset: [0, 10],
  });
  return (
    <>
      <Link
        key={index}
        className={`${styles.menuItem} ${
          isActive(item.to) && styles.activeItem
        }`}
        to={item.to}
        ref={setTriggerRef}
      >
        {item.icon()}
      </Link>
      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            className: "tooltip-container",
            style: { fontSize: "1.6rem", padding: "0.5rem 1rem" },
          })}
        >
          <div {...getArrowProps({ className: "tooltip-arrow" })} />
          {item.name}
        </div>
      )}
    </>
  );
};

export default Sidebar;
