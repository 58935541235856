import React, { useEffect, useRef, useState } from "react";
import styles from "./ProductPages.module.scss";
import "./content.scss";
import { FormGroup, RenderHtml, TextInput } from "src/components";
import { ChevronLeft, ChevronRight } from "react-feather";
import { formatCurrency } from "src/services/currency";
import { ReviewsComponent, SalesCounter } from "../components";
import { getImageUrl } from "./getImageUrl";

const Page1 = ({
  product,
  buyProduct,
  currency,
  reviews,
  salesNumber,
  payWantPrice,
  setPayWantPrice,
  loading,
  user,
  demoMode,
  loadingImages,
}: any) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isBarVisible, setIsBarVisible] = useState(true);
  const componentRef = useRef(null);

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? product.images.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === product.images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleScroll = () => {
    if (!componentRef.current) return;

    const rect = (componentRef.current as any).getBoundingClientRect();
    const viewportHeight = window.innerHeight;
    setIsBarVisible(rect.top >= viewportHeight - 100);
  };

  useEffect(() => {
    const el: any = document.getElementById("pageContainer");

    el.addEventListener("scroll", handleScroll);
    handleScroll();
    return () => {
      el.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const showArrows = product.images.length > 1;

  return (
    <div className={styles.page1} onScroll={handleScroll}>
      <div
        className={styles.header}
        style={{
          background: product.colors.find((c: any) => c.id === "background")
            .value,
          borderBottom: `2px solid ${
            product.colors.find((c: any) => c.id === "borders").value
          }`,
        }}
      >
        <h1
          style={{
            color: product.colors.find((c: any) => c.id === "title").value,
          }}
        >
          {product.name}
        </h1>
        <div className={product.payWant ? styles.payWantCta : ""}>
          {product.payWant && (
            <FormGroup
              color={product.colors.find((c: any) => c.id === "text").value}
              label="Pay what you want:"
            >
              <TextInput
                name="price"
                type="number"
                placeholder={
                  product.maxPrice
                    ? `${formatCurrency(product.maxPrice, currency)}+`
                    : "Enter amount"
                }
                value={payWantPrice}
                onChange={(e: any) => {
                  setPayWantPrice(e.target.value);
                }}
                isCurrency
                currency={currency}
                isPayWant
                backgroundColor={
                  product.colors.find((c: any) => c.id === "background").value
                }
                textColor={
                  product.colors.find((c: any) => c.id === "text").value
                }
                borderColor={
                  product.colors.find((c: any) => c.id === "borders").value
                }
                isCta
              />
            </FormGroup>
          )}
          <CtaButton
            loading={loading}
            payWantPrice={payWantPrice}
            currency={currency}
            product={product}
            buyProduct={buyProduct}
          />
        </div>
      </div>
      <div className={styles.content}>
        {product.images && product.images.length > 0 && (
          <div className={styles.imageContainer}>
            {showArrows && (
              <>
                <ChevronLeft
                  className={styles.arrowLeft}
                  onClick={handlePrevious}
                  size={40}
                />
                <ChevronRight
                  className={styles.arrowRight}
                  onClick={handleNext}
                  size={40}
                />
              </>
            )}
            <img
              src={
                loadingImages
                  ? ""
                  : getImageUrl(product.images[currentIndex]?.url, demoMode)
              }
              className={styles.image}
            />
          </div>
        )}
        <div className={styles.contentBody}>
          <div
            className={styles.left}
            style={{
              color: product.colors.find((c: any) => c.id === "text").value,
            }}
          >
            <RenderHtml html={product.description} />
          </div>
          <div className={styles.right} ref={componentRef}>
            {user && (
              <div
                className={styles.userContainer}
                style={{
                  color: product.colors.find((c: any) => c.id === "title")
                    .value,
                }}
              >
                Created by{" "}
                <img src={user.picture} className={styles.userImage} />
                <span
                  className={styles.user}
                  style={{
                    color: product.colors.find((c: any) => c.id === "title")
                      .value,
                  }}
                  onClick={() => {
                    // new tab
                    // window.open(
                    //   `https://${user.subdomain}.${process.env.REACT_APP_AUTH0_AUDIENCE}`,
                    //   "_blank"
                    // );

                    const a = document.createElement("a");
                    a.href = `https://${user.subdomain}.${process.env.REACT_APP_AUTH0_AUDIENCE}`;
                    a.rel = "noopener noreferrer";
                    a.click();
                  }}
                >
                  {user.firstName && user.lastName
                    ? user.firstName + " " + user.lastName
                    : user.family_name === "" || user.given_name === ""
                    ? user.email
                    : user.given_name + " " + user.family_name}
                </span>
              </div>
            )}
            {product.payWant && (
              <FormGroup
                color={product.colors.find((c: any) => c.id === "text").value}
                label="Pay what you want:"
              >
                <TextInput
                  name="price"
                  type="number"
                  placeholder={
                    product.maxPrice
                      ? `${formatCurrency(product.maxPrice, currency)}+`
                      : "Enter amount"
                  }
                  value={payWantPrice}
                  onChange={(e: any) => {
                    setPayWantPrice(e.target.value);
                  }}
                  isCurrency
                  currency={currency}
                  isPayWant
                  backgroundColor={
                    product.colors.find((c: any) => c.id === "background").value
                  }
                  textColor={
                    product.colors.find((c: any) => c.id === "text").value
                  }
                  borderColor={
                    product.colors.find((c: any) => c.id === "borders").value
                  }
                />
              </FormGroup>
            )}
            <CtaButton
              loading={loading}
              payWantPrice={payWantPrice}
              currency={currency}
              product={product}
              buyProduct={buyProduct}
            />
            {product.showSales && (
              <SalesCounter salesNumber={salesNumber} product={product} />
            )}
            {product.showReviews && (
              <ReviewsComponent reviews={reviews} product={product} />
            )}
            {/* {product.payWant && (
              <div className={styles.payWant}>
                <FormGroup label="Pay what you want">
                  <TextInput
                    name="price"
                    type="number"
                    placeholder="Enter amount"
                    value={finalAmount}
                    onChange={(e: any) => {
                      setFinalAmount(e.target.value);
                    }}
                  />
                </FormGroup>
              </div>
            )} */}
          </div>
        </div>
      </div>
      <div
        className={`${styles.floatingBottomBar} ${
          isBarVisible ? "" : styles.floatingBottomBarHidden
        }`}
        style={{
          background: product.colors.find((c: any) => c.id === "background")
            .value,
        }}
      >
        {product.showSales && (
          <SalesCounter
            inBottomBar
            salesNumber={salesNumber}
            product={product}
          />
        )}
        <div className={styles.bottomPayWant}>
          {product.payWant && (
            <FormGroup label="Pay what you want:">
              <TextInput
                name="price"
                type="number"
                placeholder={
                  product.maxPrice
                    ? `${formatCurrency(product.maxPrice, currency)}+`
                    : "Enter amount"
                }
                value={payWantPrice}
                onChange={(e: any) => {
                  setPayWantPrice(e.target.value);
                }}
                isCurrency
                currency={currency}
                isPayWant
                backgroundColor={
                  product.colors.find((c: any) => c.id === "background").value
                }
                textColor={
                  product.colors.find((c: any) => c.id === "text").value
                }
                borderColor={
                  product.colors.find((c: any) => c.id === "borders").value
                }
                isCta
              />
            </FormGroup>
          )}
          <CtaButton
            loading={loading}
            payWantPrice={payWantPrice}
            inBottomBar
            currency={currency}
            product={product}
            buyProduct={buyProduct}
          />
        </div>
      </div>
    </div>
  );
};

export const CtaButton = ({
  product,
  buyProduct,
  currency,
  inBottomBar,
  payWantPrice,
  loading,
}: any) => {
  return (
    <button
      className={styles.mainCta + (inBottomBar ? " " + styles.inBottomBar : "")}
      onClick={loading ? undefined : buyProduct}
      style={{
        background: product.colors.find((c: any) => c.id === "buttons").value,
        border:
          "1px solid " +
          product.colors.find((c: any) => c.id === "buttons").value,
        color: product.colors.find((c: any) => c.id === "buttonsText").value,
      }}
    >
      {loading
        ? "Loading..."
        : product.ctaText === "buyForPrice"
        ? "Buy now for " +
          formatCurrency(
            product.payWant ? payWantPrice : product.price,
            currency
          )
        : product.ctaText === "buyNow"
        ? "Buy now"
        : product.ctaText === "iWantThis"
        ? "I want this"
        : product.ctaText === "getNowFree"
        ? "Get it free"
        : product.ctaText === "buyFor0"
        ? "Buy now for " + formatCurrency(0, currency)
        : "Buy now"}
    </button>
  );
};

export default Page1;
