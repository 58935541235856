import React, { useState, useEffect, useMemo, memo } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import CheckoutForm from "./CheckoutForm";

// Import the SCSS module
import styles from "./Checkout.module.scss";
import RenderHTML from "src/components/RenderHtml/RenderHtml";
import Axios from "src/services/api";
import { ArrowDown, CheckCircle, Lock, X } from "react-feather";

// @ts-ignore
import { formatCurrency } from "src/services/currency";
import { PRODUCT } from "src/services/consts";
import { PageLoader } from "src/components/page-loader";
import { Helmet } from "react-helmet";
import toast from "react-hot-toast";
import PayPalButton from "./PayPal";
import { Button, FormGroup, TextInput } from "src/components";

import taxes from "../../../services/taxes.json";
import useTaxType from "../tax";
import { makeCdnUrl } from "src/services/cdn";

const Checkout = ({
  id,
  prod,
  upsell,
  _user,
  isProdPage,
  hidePayPal,
  demoMode,
}: any) => {
  const stripePromise = useMemo(
    async () => await loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!),
    []
  );

  const currentSubdomain = window.location.hostname.split(".")[0];
  const navigate = useNavigate();
  const [choice, setChoice] = useState<"card" | "paypal">("card");
  const [_clientSecret, setClientSecret] = useState<string>("");
  const [_paymentIntentId, setPaymentIntent] = useState<string>("");
  const { clientSecret } = useParams();

  const [searchParams] = useSearchParams();
  const productId = id || searchParams.get("productId");
  const isDirect = searchParams.get("direct") !== null;

  const payWantPrice =
    parseFloat(searchParams.get("pw") || "") || prod?.minPrice || 0;
  const payWantCurrency = searchParams.get("c") || _user?.currency || "USD";

  const [product, setProduct] = useState<any>(null);

  useEffect(() => {
    if (!product) return;

    if (
      (product?.user || _user)?.merchantIdInPayPal !== "" &&
      !(product?.user || _user)?.stripeConnectedAccountId
    ) {
      setChoice("paypal");
    } else {
      setChoice("card");
    }
  }, [product]);

  const [country, setCountry] = useState<string>("US");
  const [vat, setVat] = useState("");
  const [city, setCity] = useState<string>("Houston");
  const [regionCode, setRegionCode] = useState<string>("TX");
  const [ip, setIp] = useState<string>("");
  const [zip, setZip] = useState<string>("0000");
  const [state, setState] = useState<string>("Texas");

  const [currency, setCurrency] = useState<string>("USD");

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (clientSecret && !isDirect && !_user?.stripeConnectedAccountId) return;

    if (demoMode) return;

    setIsLoading(true);
    const _payWantPrice =
      !payWantPrice || (payWantPrice as unknown as string) === ""
        ? 0
        : payWantPrice;
    Axios.post("/products/create-payment-intent", {
      productId,
      country,
      zip,
      state,
      city,
      regionCode,
      currency,
      ip,

      payWantPrice: _payWantPrice,
    }).then((res) => {
      setIsLoading(false);
      // setClientSecret(res.data.clientSecret);
      // setPaymentIntent(res.data.paymentIntentId);
      navigate(
        `/checkout/${res.data.clientSecret}/${res.data.paymentIntentId}?productId=${productId}&pw=${_payWantPrice}&c=${currency}`
      );
    });
  }, [productId, country, zip, state, currency, payWantPrice]);

  useEffect(() => {
    if ((prod && id) || upsell) return;

    if (!productId) return;

    Axios.post(`/dashboard/visitor`, {
      productId: productId,
      page: window.location.pathname,
    }).then((res) => {
      console.log(res.data);
    });

    Axios.get(
      "/products/verify-subdomain/" + currentSubdomain + "/" + productId
    )
      .then(() => {
        console.log("subdomain verified");
      })
      .catch((error) => {
        if (error.response.data.message) {
          toast.error(error.response.data.message);
          navigate("/");
        }
      });
  }, []);

  useEffect(() => {
    let timeoutId: any;

    if (demoMode && prod) {
      let htmlContent = prod?.description;
      const youtubeUrls = htmlContent?.match(/<video src="([^"]*)"><\/video>/g); // Extract <video> tags with YouTube URLs
      youtubeUrls?.forEach((videoTag: any) => {
        const youtubeUrl = videoTag?.match(/src="([^"]*)/)[1]; // Extract YouTube URL from the video tag
        const youtubeId = youtubeUrl.split("embed/")[1]; // Extract YouTube video ID

        // Generate iframe equivalent
        const iframeEquivalent = `
          <iframe 
            width="560" 
            height="315" 
            src="https://www.youtube.com/embed/${youtubeId}" 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowfullscreen>
          </iframe>
        `;

        htmlContent = htmlContent?.replace(videoTag, iframeEquivalent); // Replace specific video tag with iframe
      });

      const borderColor = prod?.colors.find(
        (c: any) => c.id === "borders"
      )?.value;

      htmlContent = htmlContent?.replace(
        /<hr>/g,
        `<hr style="border: none; border-top: 1px solid ${borderColor};">`
      );
      htmlContent = htmlContent?.replace(
        /<hr>/g,
        `<hr style="border: none; border-top: 1px solid ${borderColor};">`
      );

      htmlContent = htmlContent?.replace(/<p><\/p>/g, "<p><br></p>");

      setProduct({
        ...prod,
        description: htmlContent,
      });

      if (_user?.stripeConnectedAccountId && prod?.price > 0) {
        setIsLoading(true);
        Axios.post("/products/create-payment-intent", {
          productId: prod?._id,
          country,
          zip,
          state,
          currency,
          payWantPrice,

          prod: {
            userId: _user.userId,
            payWant: prod?.payWant,
            minPrice: prod?.minPrice,
            price: prod?.price,
          },
        })
          .then((res) => {
            setClientSecret(res.data.clientSecret);
            setPaymentIntent(res.data.paymentIntentId);
            setIsLoading(false);
          })
          .catch(() => {
            setIsLoading(false);
          });

        // Axios.get("https://api.ipgeolocation.io/ipgeo", {
        //   params: {
        //     apiKey: "5c920f814db94414b5f7f70bd9dd56de",
        //   },
        // })
        //   .then((resIQ) => {
        //     const currency = resIQ.data.currency.code;
        //     const country = resIQ.data.country_code2;
        //     const zip = resIQ.data.postal;
        //     const state = resIQ.data.region;
        //   })
        //   .catch(() => {
        //     setIsLoading(false);
        //   });
      }
    } else if (upsell) {
      Axios.get(`/products/prod/${upsell.upsellProductId}`).then((res) => {
        setProduct({
          ...PRODUCT,
          upsell: {
            ...upsell,
            upsellProduct: {
              ...res.data,
            },
          },
        });
      });
      timeoutId = setTimeout(() => {
        let htmlContent = prod?.description;
        const youtubeUrls = htmlContent?.match(
          /<video src="([^"]*)"><\/video>/g
        ); // Extract <video> tags with YouTube URLs

        youtubeUrls?.forEach((videoTag: any) => {
          const youtubeUrl = videoTag?.match(/src="([^"]*)/)[1]; // Extract YouTube URL from the video tag
          const youtubeId = youtubeUrl.split("embed/")[1]; // Extract YouTube video ID

          // Generate iframe equivalent
          const iframeEquivalent = `
          <iframe 
            width="560" 
            height="315" 
            src="https://www.youtube.com/embed/${youtubeId}" 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowfullscreen>
          </iframe>
        `;

          htmlContent = htmlContent?.replace(videoTag, iframeEquivalent); // Replace specific video tag with iframe
        });

        const borderColor = prod?.colors.find(
          (c: any) => c.id === "borders"
        )?.value;

        htmlContent = htmlContent?.replace(
          /<hr>/g,
          `<hr style="border: none; border-top: 1px solid ${borderColor};">`
        );
        htmlContent = htmlContent?.replace(
          /<hr>/g,
          `<hr style="border: none; border-top: 1px solid ${borderColor};">`
        );

        htmlContent = htmlContent?.replace(/<p><\/p>/g, "<p><br></p>");

        setProduct({
          ...prod,
          description: htmlContent,
        });

        if (
          ((prod?.price > 0 && !prod?.payWant) ||
            (prod?.payWant && payWantPrice > 0)) &&
          id &&
          _user?.stripeConnectedAccountId
        ) {
          setIsLoading(true);
          Axios.post("/products/create-payment-intent", {
            productId: id,
            // country,
            // zip,
            // state,
            currency,
            payWantPrice,

            prod: {
              userId: _user.userId,
              payWant: prod?.payWant,
              minPrice: prod?.minPrice,
              price: prod?.price,
            },
          })
            .then((res) => {
              setClientSecret(res.data.clientSecret);
              setPaymentIntent(res.data.paymentIntentId);
              setIsLoading(false);
            })
            .catch(() => {
              setIsLoading(false);
            });
          // Axios.get("https://api.ipgeolocation.io/ipgeo", {
          //   params: {
          //     apiKey: "5c920f814db94414b5f7f70bd9dd56de",
          //   },
          // })
          //   .then((resIQ) => {
          //     const currency = resIQ.data.currency.code;
          //     const country = resIQ.data.country_code2;
          //     const zip = resIQ.data.postal;
          //     const state = resIQ.data.region;

          //   })
          //   .catch(() => {
          //     setIsLoading(false);
          //   });
        }
      }, 500);
    } else if (productId) {
      setIsLoading(true);
      Axios.get(`/products/prod/${productId}`).then((res) => {
        let htmlContent = res.data.description;
        const youtubeUrls = htmlContent?.match(
          /<video src="([^"]*)"><\/video>/g
        ); // Extract <video> tags with YouTube URLs
        youtubeUrls?.forEach((videoTag: any) => {
          const youtubeUrl = videoTag?.match(/src="([^"]*)/)[1]; // Extract YouTube URL from the video tag
          const youtubeId = youtubeUrl.split("embed/")[1]; // Extract YouTube video ID

          // Generate iframe equivalent
          const iframeEquivalent = `
          <iframe 
            width="560" 
            height="315" 
            src="https://www.youtube.com/embed/${youtubeId}" 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowfullscreen>
          </iframe>
        `;

          htmlContent = htmlContent?.replace(videoTag, iframeEquivalent); // Replace specific video tag with iframe
        });

        const borderColor = res.data.colors.find(
          (c: any) => c.id === "borders"
        )?.value;

        htmlContent = htmlContent?.replace(
          /<hr>/g,
          `<hr style="border: none; border-top: 1px solid ${borderColor};">`
        );
        htmlContent = htmlContent?.replace(
          /<hr>/g,
          `<hr style="border: none; border-top: 1px solid ${borderColor};">`
        );

        htmlContent = htmlContent?.replace(/<p><\/p>/g, "<p><br></p>");

        setProduct({
          ...res.data,
          description: htmlContent,
        });

        Axios.get(`/images/product/${res.data._id}`).then((resImages) => {
          setProduct({
            ...res.data,
            images: resImages.data,
            description: htmlContent,
          });

          // setIsLoading(false);

          // get the PPP price
          // let pppProductPrice = res.data.price;
          // fetchPPP().then((response: any) => {
          //   pppProductPrice = response.ppp.pppConversionFactor * res.data.price;
          // });

          Axios.get(`/products/ppp/${res.data._id}`)
            .then((resPPP) => {
              setCurrency(resPPP.data.currency);
              setProduct({
                ...res.data,
                description: htmlContent,
                images: resImages.data,
                price: resPPP.data.price,
                conversionRate: resPPP.data.conversionRate,
                upsell: {
                  ...res.data.upsell,
                  upsellPrice: resPPP.data.upsell.upsellPrice,
                  upsellProduct: {
                    ...res.data.upsell.upsellProduct,
                    price: resPPP.data.upsell.upsellPrice,
                  },
                },
              });
              setIsLoading(false);
            })
            .catch(() => {
              setIsLoading(false);
            });

          // Axios.get("https://api.ipgeolocation.io/ipgeo", {
          //   params: {
          //     apiKey: "5c920f814db94414b5f7f70bd9dd56de",
          //   },
          // })
          //   .then((resIQ) => {
          //   })
          //   .catch(() => {
          //     setIsLoading(false);
          //   });
        });
      });
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [productId, id, prod, upsell]);

  const options = useMemo(() => {
    if (!clientSecret && !_clientSecret) return;

    return {
      clientSecret: clientSecret || _clientSecret,
      appearance: {
        theme: "stripe" as const,
      },
    };
  }, [clientSecret, _clientSecret]);

  const [refundPolicy, setRefundPolicy] = useState<any>(null);

  useEffect(() => {
    if (prod?.refundPolicy) {
      Axios.get(`/refunds/${prod?.refundPolicy}`).then((res) => {
        setRefundPolicy(res.data);
      });
    } else {
      if (!product) return;
      if (product.refundPolicy) {
        Axios.get(`/refunds/${product.refundPolicy}`).then((res) => {
          setRefundPolicy(res.data);
        });
      } else {
        setRefundPolicy(null);
      }
    }
  }, [product, prod]);

  const [hasDiscounts, setHasDiscounts] = useState<boolean>(false);
  const [discountCode, setDiscountCode] = useState<string>("");
  const [discountApplied, setDiscountApplied] = useState<boolean>(false);
  const [discount, setDiscount] = useState<any>(null);

  useEffect(() => {
    if (!product || _user) return;

    Axios.get(`/discounts/check-product/${product._id}`).then((res) => {
      setHasDiscounts(res.data);
    });
  }, [product]);

  const prodPrice = useMemo(
    () =>
      discountApplied
        ? product?.payWant
          ? payWantPrice -
            (discount?.valueType === "percentage"
              ? (discount?.value / 100) * payWantPrice
              : discount?.value)
          : product?.price -
            (discount?.valueType === "percentage"
              ? (discount?.value / 100) * product?.price
              : discount?.value)
        : product?.payWant
        ? payWantPrice
        : product?.price,
    [discountApplied, discount, product, payWantPrice]
  );

  const [addedPayPalUpsell, setAddedPayPalUpsell] = useState<boolean>(false);

  const [discountError, setDiscountError] = useState<string>("");

  const [taxPercentage, setTaxPercentage] = useState<any>(null);
  const [taxCountry, setTaxCountry] = useState<any>(null);

  useEffect(() => {
    if (_user || prodPrice === 0) return;

    Axios.get("https://api.ipgeolocation.io/ipgeo", {
      params: {
        apiKey: "5c920f814db94414b5f7f70bd9dd56de",
      },
    })
      .then((res) => {
        setTaxCountry(res.data.country_code2);
        const countryName: string = res.data.country_name;
        const state: string = res.data.state_prov;

        if (countryName === "United States" || countryName === "Canada") {
          // @ts-ignore
          setTaxPercentage(taxes[countryName][state]);
        } else {
          // @ts-ignore
          setTaxPercentage(taxes[countryName]);
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [_user]);

  const taxType = useTaxType(taxCountry);

  const taxAmount = _clientSecret
    ? 0
    : parseFloat(
        (product?.payWant ? payWantPrice : product?.price) +
          (addedPayPalUpsell ? product?.upsell?.upsellPrice : 0)
      ) *
      (taxPercentage / 100);
  const total = _clientSecret
    ? product?.payWant
      ? payWantPrice
      : product?.price
    : parseFloat(
        (product?.payWant ? payWantPrice : product?.price) +
          (addedPayPalUpsell ? product?.upsell?.upsellPrice : 0)
      ) + taxAmount;

  if ((!isProdPage && !upsell && prod) || !product || isLoading) {
    return <PageLoader />;
  }

  const getThumbnail = () => {
    if (demoMode) {
      return (
        (product?.thumbnail && product?.thumbnail !== ""
          ? product?.thumbnail
          : null) || "https://via.placeholder.com/600?text=Product+Image"
      );
    }

    if (product?.thumbnail) {
      return makeCdnUrl(product?.thumbnail);
    } else if (product?.images?.length > 0) {
      return makeCdnUrl(product?.images[0]?.url);
    } else {
      return "https://via.placeholder.com/600?text=Product+Image";
    }
  };

  return (
    <div className={styles.container}>
      {/* scroll down arrow, scroll to paymentSection */}
      {/* {!isBottom && (
        <div className={styles.scrollArrow} onClick={scrollToBottom}>
          <ArrowDown />
        </div>
      )} */}
      {!prod && !upsell && (
        <Helmet>
          <meta charSet="UTF-8" />
          <title>{product.name}</title>
          <meta
            name="description"
            content={product.description?.replace(/<[^>]+>/g, "")}
          />
          {/* <link rel="canonical" href="URL of your main page" /> */}
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta name="robots" content="index, follow" />
          <meta property="og:title" content={product.name} />
          <meta
            property="og:description"
            content={product.description?.replace(/<[^>]+>/g, "")}
          />
          <meta
            property="og:image"
            content={product.thumbnail || product?.images[0]?.url}
          />
          {/* <meta property="og:url" content={} /> */}
          <meta property="og:site_name" content="Pocketsflow" />
          <meta property="og:type" content="Digital Product" />
          <meta name="twitter:card" content="summary" />
          {/* <meta name="twitter:site" content="@yourtwitterusername" /> */}
          <meta name="twitter:title" content={product.name} />
          <meta
            name="twitter:description"
            content={product.description?.replace(/<[^>]+>/g, "").slice(0, 200)}
          />
          <meta
            name="twitter:image"
            content={product.thumbnail || product?.images[0]?.url}
          />
        </Helmet>
      )}
      <div className={styles.leftSection}>
        <div className={styles.prod}>
          <div className={styles.productSummary}>
            <h2 className={styles.title}>{product.name}</h2>
            <p className={styles.price}>
              {formatCurrency(
                product.payWant ? payWantPrice : product.price,
                currency
              )}
            </p>
          </div>
          <div className={styles.imageContainer}>
            <img
              className={styles.image}
              src={getThumbnail()}
              alt={product.name}
            />
          </div>
          <div className={styles.description}>
            <RenderHTML
              html={product.checkoutText?.replace("<p><br></p>", "<br>")}
            />
          </div>
        </div>
      </div>
      <div className={styles.rightSection} id="paymentSection">
        <div className={styles.paymentContainer} key={clientSecret}>
          <h2>Payment Details</h2>

          <p>
            {product.price > 0
              ? "Complete your purchase by providing your payment details"
              : "Get the product by providing your email"}
          </p>

          {hasDiscounts &&
            (product?.payWant ? payWantPrice > 0 : product?.price > 0) && (
              <form
                onSubmit={(e) => {
                  e.preventDefault();

                  toast.promise(
                    Axios.get(`/discounts/apply/${discountCode}/${product._id}`)
                      .then((res) => {
                        setDiscount(res.data);
                        setDiscountApplied(true);
                        setDiscountError("");
                      })
                      .catch(() => {
                        setDiscount(null);
                        setDiscountApplied(false);
                        setDiscountError("Invalid discount code");
                      }),
                    {
                      loading: "Applying discount...",
                      success: "Discount applied",
                      error: "Error applying discount",
                    }
                  );
                }}
              >
                <div className={styles.discount}>
                  <FormGroup label="Discount code" error={discountError}>
                    <div className={styles.discountGrid}>
                      <TextInput
                        name="discountCode"
                        value={discountCode}
                        error={discountError}
                        onChange={(e) => setDiscountCode(e.target.value)}
                        placeholder="Enter discount code"
                      />
                      <Button text="Apply" type="submit" />
                    </div>
                  </FormGroup>
                </div>
              </form>
            )}

          {(product?.user || _user)?.merchantIdInPayPal &&
            (product?.user || _user)?.merchantIdInPayPal !== "" &&
            prodPrice > 0 &&
            !!(product?.user || _user)?.stripeConnectedAccountId && (
              <div className={styles.tabs}>
                <div
                  className={`${styles.tab} ${
                    choice === "card" ? styles.active : ""
                  }`}
                  onClick={() => setChoice("card")}
                >
                  Card
                </div>
                <div
                  className={`${styles.tab} ${
                    choice === "paypal" ? styles.active : ""
                  }`}
                  onClick={() => setChoice("paypal")}
                >
                  PayPal
                </div>
              </div>
            )}

          {choice === "paypal" && prodPrice > 0 ? (
            <div className={styles.paypalSection}>
              {product?.upsell?.upsellProduct?._id && (
                <div className={styles.upsellPayPal}>
                  <div className={styles.upselllProduct}>
                    {addedPayPalUpsell && (
                      <div
                        className={styles.x}
                        onClick={() => setAddedPayPalUpsell(!addedPayPalUpsell)}
                      >
                        <X size={18} />
                      </div>
                    )}
                    <div className={styles.upsellImageContainer}>
                      <img
                        className={styles.image}
                        src={
                          product?.upsell?.upsellProduct.thumbnail ||
                          "https://via.placeholder.com/600?text=Product+Image"
                        }
                        alt={product?.upsell?.upsellProduct.name}
                      />
                    </div>
                    <div className={styles.upsellTextSide}>
                      <div className={styles.upsellTitle}>
                        {product?.upsell?.upsellProduct.name}
                      </div>
                      <a className={styles.navBrand}>
                        <img
                          src={(product?.user || _user)?.picture}
                          alt="Creator Profile Picture"
                          className={styles.creatorProfilePicture}
                        />
                        <span className={styles.creatorName}>
                          {(product?.user || _user)?.firstName ||
                            (product?.user || _user)?.name}{" "}
                          {(product?.user || _user)?.lastName}
                        </span>
                      </a>
                      <div className={styles.upsellProductPrice}>
                        {formatCurrency(product?.upsell?.upsellPrice, currency)}
                        <Button
                          variant={addedPayPalUpsell ? "success" : "primary"}
                          text={
                            addedPayPalUpsell ? (
                              <div className={styles.btnText}>
                                <CheckCircle size={14} color="white" />
                                Added to order
                              </div>
                            ) : (
                              product?.upsell?.primaryButtonText ||
                              "Yes, add to my order"
                            )
                          }
                          onClick={() => {
                            setAddedPayPalUpsell(!addedPayPalUpsell);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {prodPrice > 0 && (
                <>
                  <br />
                  <br />
                  <FormGroup label="VAT number (optional)">
                    <TextInput
                      name="vat"
                      type="vat"
                      value={vat}
                      onChange={(e: any) => setVat(e.target.value)}
                      placeholder="VAT number / Tax ID"
                    />
                  </FormGroup>
                </>
              )}

              <div
                className={
                  styles.totalSection + " " + styles.totalSectionPaypal
                }
              >
                <div className={styles.total}>
                  <span>Price</span>
                  <div>
                    {product?.payWant
                      ? formatCurrency(
                          payWantPrice +
                            (addedPayPalUpsell
                              ? product?.upsell?.upsellPrice
                              : 0),
                          currency
                        )
                      : product?.price > 0
                      ? formatCurrency(
                          product?.price +
                            (addedPayPalUpsell
                              ? product?.upsell?.upsellPrice
                              : 0),
                          currency
                        )
                      : formatCurrency(0, currency)}
                  </div>
                </div>
                {/* {taxPercentage !== undefined && (
                  <div className={styles.total}>
                    <span>
                      {taxType} {product?.price > 0 ? `(${taxPercentage}%)` : ""}
                    </span>
                    <div>
                      {product?.price > 0
                        ? formatCurrency(taxAmount, currency)
                        : formatCurrency(0, currency)}
                    </div>
                  </div>
                )} */}

                {discountApplied && (
                  <>
                    <div className={styles.total}>
                      <span>
                        Discount (
                        {discount?.valueType === "percentage"
                          ? `${discount?.value}% off`
                          : formatCurrency(discount?.value) + " off"}
                        )
                      </span>
                      <div>
                        -{" "}
                        {formatCurrency(
                          // Discount Amount
                          discount?.valueType === "percentage"
                            ? product?.payWant
                              ? (payWantPrice +
                                  (addedPayPalUpsell
                                    ? product?.upsell?.upsellPrice
                                    : 0)) *
                                (discount?.value / 100)
                              : (product?.price +
                                  (addedPayPalUpsell
                                    ? product?.upsell?.upsellPrice
                                    : 0)) *
                                (discount?.value / 100)
                            : discount?.value,
                          currency
                        )}
                      </div>
                    </div>
                    <div className={styles.total}>
                      <span>Subtotal</span>
                      <div>
                        {formatCurrency(
                          (product?.payWant ? payWantPrice : product?.price) +
                            (addedPayPalUpsell
                              ? product?.upsell?.upsellPrice
                              : 0) -
                            (discount?.valueType === "percentage"
                              ? product?.payWant
                                ? (payWantPrice +
                                    (addedPayPalUpsell
                                      ? product?.upsell?.upsellPrice
                                      : 0)) *
                                  (discount?.value / 100)
                                : (product?.price +
                                    (addedPayPalUpsell
                                      ? product?.upsell?.upsellPrice
                                      : 0)) *
                                  (discount?.value / 100)
                              : discount?.value),
                          currency
                        )}
                      </div>
                    </div>
                  </>
                )}

                {taxPercentage !== undefined && (
                  <div className={styles.total}>
                    <span>
                      Tax ({taxType} {taxPercentage}%)
                    </span>
                    <div>
                      {formatCurrency(
                        discountApplied
                          ? (product?.payWant
                              ? total -
                                (discount?.valueType === "percentage"
                                  ? (discount?.value / 100) * total
                                  : discount?.value)
                              : product?.price > 0
                              ? total -
                                (discount?.valueType === "percentage"
                                  ? (discount?.value / 100) * total
                                  : discount?.value)
                              : 0) -
                              ((product?.payWant
                                ? payWantPrice +
                                  (addedPayPalUpsell
                                    ? product?.upsell?.upsellPrice
                                    : 0)
                                : product?.price +
                                  (addedPayPalUpsell
                                    ? product?.upsell?.upsellPrice
                                    : 0)) -
                                (discount?.valueType === "percentage"
                                  ? product?.payWant
                                    ? (payWantPrice +
                                        (addedPayPalUpsell
                                          ? product?.upsell?.upsellPrice
                                          : 0)) *
                                      (discount?.value / 100)
                                    : (product?.price +
                                        (addedPayPalUpsell
                                          ? product?.upsell?.upsellPrice
                                          : 0)) *
                                      (discount?.value / 100)
                                  : discount?.value))
                          : taxAmount,

                        currency
                      )}
                    </div>
                  </div>
                )}

                {!discountApplied && (
                  <div className={styles.total}>
                    <span>Total</span>
                    <div>
                      {product?.payWant
                        ? formatCurrency(total, currency)
                        : product?.price > 0
                        ? formatCurrency(total, currency)
                        : formatCurrency(0, currency)}
                    </div>
                  </div>
                )}

                {discountApplied && (
                  <div className={styles.total}>
                    <span>Total</span>
                    <div>
                      {product?.payWant
                        ? formatCurrency(
                            total -
                              (discount?.valueType === "percentage"
                                ? (discount?.value / 100) * total
                                : discount?.value),
                            currency
                          )
                        : product?.price > 0
                        ? formatCurrency(
                            total -
                              (discount?.valueType === "percentage"
                                ? (discount?.value / 100) * total
                                : discount?.value),
                            currency
                          )
                        : formatCurrency(0, currency)}
                    </div>
                  </div>
                )}
              </div>

              {!hidePayPal && (
                <PayPalButton
                  discount={discount}
                  merchantId={(product?.user || _user)?.merchantIdInPayPal}
                  amount={prodPrice}
                  currency={currency}
                  currentSubdomain={currentSubdomain}
                  product={product}
                  user={product.user || _user}
                  country={country}
                  city={city}
                  regionCode={regionCode}
                  ip={ip}
                  zip={zip}
                  state={state}
                  addedPayPalUpsell={addedPayPalUpsell}
                  demoMode={demoMode}
                  taxPercentage={taxPercentage}
                  vatNumber={vat}
                />
              )}
            </div>
          ) : (
            <Elements stripe={stripePromise} options={options}>
              <CheckoutForm
                payWantPrice={payWantPrice}
                payWantCurrency={payWantCurrency}
                country={country}
                zip={zip}
                state={state}
                currency={currency}
                city={city}
                regionCode={regionCode}
                ip={ip}
                user={product?.user || _user}
                product={product || prod}
                _upsell={upsell}
                _clientSecret={_clientSecret}
                _paymentIntentId={_paymentIntentId}
                discountApplied={discountApplied}
                discount={discount}
                taxPercentage={taxPercentage}
                taxCountry={taxCountry}
              />
            </Elements>
          )}
          <div className={styles.secureBanner}>
            <Lock size={14} />
            <span>Encrypted and secured payments</span>
          </div>
          {refundPolicy && (
            <div className={styles.refund}>
              <a href={`/refund-policy/${refundPolicy._id}`} target="_blank">
                {refundPolicy.period} {refundPolicy.periodType} refund policy
              </a>
            </div>
          )}
          <div className={styles.footer}>
            Powered by{" "}
            <a href="https://www.pocketsflow.com/" target="_blank">
              Pocketsflow
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
