import currency from "currency.js";

export const formatCurrency = (price: number, _currency?: string) => {
  if (!_currency) {
    const me =
      localStorage.getItem("me") && JSON.parse(localStorage.getItem("me")!);
    return currency(price, {
      symbol: currencies.find((c) => c.value === me?.currency)?.symbol,
    }).format();
  } else {
    if (_currency === "0") {
      return currency(price).format();
    }
    return currency(price, {
      symbol: currencies.find((c) => c.value === _currency)?.symbol,
    }).format();
  }
};

export const currencies = [
  { value: "AED", label: "AED — United Arab Emirates Dirham", symbol: "د.إ" },
  { value: "AFN", label: "AFN — Afghan Afghani", symbol: "؋" },
  { value: "ALL", label: "ALL — Albanian Lek", symbol: "L" },
  { value: "AMD", label: "AMD — Armenian Dram", symbol: "֏" },
  { value: "ANG", label: "ANG — Netherlands Antillean Guilder", symbol: "ƒ" },
  { value: "AOA", label: "AOA — Angolan Kwanza", symbol: "Kz" },
  { value: "ARS", label: "ARS — Argentine Peso", symbol: "$" },
  { value: "AUD", label: "AUD — Australian Dollar", symbol: "$" },
  { value: "AWG", label: "AWG — Aruban Florin", symbol: "ƒ" },
  { value: "AZN", label: "AZN — Azerbaijani Manat", symbol: "₼" },
  {
    value: "BAM",
    label: "BAM — Bosnia-Herzegovina Convertible Mark",
    symbol: "KM",
  },
  { value: "BBD", label: "BBD — Barbadian Dollar", symbol: "$" },
  { value: "BDT", label: "BDT — Bangladeshi Taka", symbol: "৳" },
  { value: "BGN", label: "BGN — Bulgarian Lev", symbol: "лв" },
  { value: "BHD", label: "BHD — Bahraini Dinar", symbol: ".د.ب" },
  { value: "BIF", label: "BIF — Burundian Franc", symbol: "Fr" },
  { value: "BMD", label: "BMD — Bermudan Dollar", symbol: "$" },
  { value: "BND", label: "BND — Brunei Dollar", symbol: "$" },
  { value: "BOB", label: "BOB — Bolivian Boliviano", symbol: "Bs" },
  { value: "BRL", label: "BRL — Brazilian Real", symbol: "R$" },
  { value: "BSD", label: "BSD — Bahamian Dollar", symbol: "$" },
  { value: "BTC", label: "BTC — Bitcoin", symbol: "₿" },
  { value: "BTN", label: "BTN — Bhutanese Ngultrum", symbol: "Nu" },
  { value: "BWP", label: "BWP — Botswanan Pula", symbol: "P" },
  { value: "BYN", label: "BYN — Belarusian Ruble", symbol: "Br" },
  { value: "BYR", label: "BYR — Belarusian Ruble (pre-2016)", symbol: "Br" },
  { value: "BZD", label: "BZD — Belize Dollar", symbol: "BZ$" },
  { value: "CAD", label: "CAD — Canadian Dollar", symbol: "$" },
  { value: "CDF", label: "CDF — Congolese Franc", symbol: "Fr" },
  { value: "CHF", label: "CHF — Swiss Franc", symbol: "Fr" },
  { value: "CLF", label: "CLF — Chilean Unit of Account (UF)", symbol: "UF" },
  { value: "CLP", label: "CLP — Chilean Peso", symbol: "$" },
  { value: "CNY", label: "CNY — Chinese Yuan", symbol: "¥" },
  { value: "COP", label: "COP — Colombian Peso", symbol: "$" },
  { value: "CRC", label: "CRC — Costa Rican Colón", symbol: "₡" },
  { value: "CUC", label: "CUC — Cuban Convertible Peso", symbol: "$" },
  { value: "CUP", label: "CUP — Cuban Peso", symbol: "₱" },
  { value: "CVE", label: "CVE — Cape Verdean Escudo", symbol: "$" },
  { value: "CZK", label: "CZK — Czech Koruna", symbol: "Kč" },
  { value: "DJF", label: "DJF — Djiboutian Franc", symbol: "Fr" },
  { value: "DKK", label: "DKK — Danish Krone", symbol: "kr" },
  { value: "DOP", label: "DOP — Dominican Peso", symbol: "RD$" },
  { value: "DZD", label: "DZD — Algerian Dinar", symbol: "دج" },
  { value: "EGP", label: "EGP — Egyptian Pound", symbol: "£" },
  { value: "ERN", label: "ERN — Eritrean Nakfa", symbol: "Nfk" },
  { value: "ETB", label: "ETB — Ethiopian Birr", symbol: "Br" },
  { value: "EUR", label: "EUR — Euro", symbol: "€" },
  { value: "FJD", label: "FJD — Fijian Dollar", symbol: "$" },
  { value: "FKP", label: "FKP — Falkland Islands Pound", symbol: "£" },
  { value: "GBP", label: "GBP — British Pound Sterling", symbol: "£" },
  { value: "GEL", label: "GEL — Georgian Lari", symbol: "₾" },
  { value: "GGP", label: "GGP — Guernsey Pound", symbol: "£" },
  { value: "GHS", label: "GHS — Ghanaian Cedi", symbol: "₵" },
  { value: "GIP", label: "GIP — Gibraltar Pound", symbol: "£" },
  { value: "GMD", label: "GMD — Gambian Dalasi", symbol: "D" },
  { value: "GNF", label: "GNF — Guinean Franc", symbol: "Fr" },
  { value: "GTQ", label: "GTQ — Guatemalan Quetzal", symbol: "Q" },
  { value: "GYD", label: "GYD — Guyanaese Dollar", symbol: "$" },
  { value: "HKD", label: "HKD — Hong Kong Dollar", symbol: "$" },
  { value: "HNL", label: "HNL — Honduran Lempira", symbol: "L" },
  { value: "HRK", label: "HRK — Croatian Kuna", symbol: "kn" },
  { value: "HTG", label: "HTG — Haitian Gourde", symbol: "G" },
  { value: "HUF", label: "HUF — Hungarian Forint", symbol: "Ft" },
  { value: "IDR", label: "IDR — Indonesian Rupiah", symbol: "Rp" },
  { value: "ILS", label: "ILS — Israeli New Shekel", symbol: "₪" },
  { value: "IMP", label: "IMP — Manx Pound", symbol: "£" },
  { value: "INR", label: "INR — Indian Rupee", symbol: "₹" },
  { value: "IQD", label: "IQD — Iraqi Dinar", symbol: "ع.د" },
  { value: "IRR", label: "IRR — Iranian Rial", symbol: "﷼" },
  { value: "ISK", label: "ISK — Icelandic Króna", symbol: "kr" },
  { value: "JEP", label: "JEP — Jersey Pound", symbol: "£" },
  { value: "JMD", label: "JMD — Jamaican Dollar", symbol: "J$" },
  { value: "JOD", label: "JOD — Jordanian Dinar", symbol: "د.ا" },
  { value: "JPY", label: "JPY — Japanese Yen", symbol: "¥" },
  { value: "KES", label: "KES — Kenyan Shilling", symbol: "KSh" },
  { value: "KGS", label: "KGS — Kyrgystani Som", symbol: "с" },
  { value: "KHR", label: "KHR — Cambodian Riel", symbol: "៛" },
  { value: "KMF", label: "KMF — Comorian Franc", symbol: "Fr" },
  { value: "KPW", label: "KPW — North Korean Won", symbol: "₩" },
  { value: "KRW", label: "KRW — South Korean Won", symbol: "₩" },
  { value: "KWD", label: "KWD — Kuwaiti Dinar", symbol: "د.ك" },
  { value: "KYD", label: "KYD — Cayman Islands Dollar", symbol: "$" },
  { value: "KZT", label: "KZT — Kazakhstani Tenge", symbol: "₸" },
  { value: "LAK", label: "LAK — Laotian Kip", symbol: "₭" },
  { value: "LBP", label: "LBP — Lebanese Pound", symbol: "ل.ل" },
  { value: "LKR", label: "LKR — Sri Lankan Rupee", symbol: "₨" },
  { value: "LRD", label: "LRD — Liberian Dollar", symbol: "$" },
  { value: "LSL", label: "LSL — Lesotho Loti", symbol: "L" },
  { value: "LTL", label: "LTL — Lithuanian Litas", symbol: "Lt" },
  { value: "LVL", label: "LVL — Latvian Lats", symbol: "Ls" },
  { value: "LYD", label: "LYD — Libyan Dinar", symbol: "ل.د" },
  { value: "MAD", label: "MAD — Moroccan Dirham", symbol: "د.م." },
  { value: "MDL", label: "MDL — Moldovan Leu", symbol: "L" },
  { value: "MGA", label: "MGA — Malagasy Ariary", symbol: "Ar" },
  { value: "MKD", label: "MKD — Macedonian Denar", symbol: "ден" },
  { value: "MMK", label: "MMK — Myanma Kyat", symbol: "K" },
  { value: "MNT", label: "MNT — Mongolian Tugrik", symbol: "₮" },
  { value: "MOP", label: "MOP — Macanese Pataca", symbol: "P" },
  { value: "MRO", label: "MRO — Mauritanian Ouguiya", symbol: "UM" },
  { value: "MUR", label: "MUR — Mauritian Rupee", symbol: "₨" },
  { value: "MVR", label: "MVR — Maldivian Rufiyaa", symbol: "ރ." },
  { value: "MWK", label: "MWK — Malawian Kwacha", symbol: "MK" },
  { value: "MXN", label: "MXN — Mexican Peso", symbol: "$" },
  { value: "MYR", label: "MYR — Malaysian Ringgit", symbol: "RM" },
  { value: "MZN", label: "MZN — Mozambican Metical", symbol: "MT" },
  { value: "NAD", label: "NAD — Namibian Dollar", symbol: "$" },
  { value: "NGN", label: "NGN — Nigerian Naira", symbol: "₦" },
  { value: "NIO", label: "NIO — Nicaraguan Córdoba", symbol: "C$" },
  { value: "NOK", label: "NOK — Norwegian Krone", symbol: "kr" },
  { value: "NPR", label: "NPR — Nepalese Rupee", symbol: "₨" },
  { value: "NZD", label: "NZD — New Zealand Dollar", symbol: "$" },
  { value: "OMR", label: "OMR — Omani Rial", symbol: "ر.ع." },
  { value: "PAB", label: "PAB — Panamanian Balboa", symbol: "B/." },
  { value: "PEN", label: "PEN — Peruvian Sol", symbol: "S/." },
  { value: "PGK", label: "PGK — Papua New Guinean Kina", symbol: "K" },
  { value: "PHP", label: "PHP — Philippine Peso", symbol: "₱" },
  { value: "PKR", label: "PKR — Pakistani Rupee", symbol: "₨" },
  { value: "PLN", label: "PLN — Polish Złoty", symbol: "zł" },
  { value: "PYG", label: "PYG — Paraguayan Guarani", symbol: "₲" },
  { value: "QAR", label: "QAR — Qatari Rial", symbol: "ر.ق" },
  { value: "RON", label: "RON — Romanian Leu", symbol: "lei" },
  { value: "RSD", label: "RSD — Serbian Dinar", symbol: "Дин." },
  { value: "RUB", label: "RUB — Russian Ruble", symbol: "₽" },
  { value: "RWF", label: "RWF — Rwandan Franc", symbol: "Fr" },
  { value: "SAR", label: "SAR — Saudi Riyal", symbol: "ر.س" },
  { value: "SBD", label: "SBD — Solomon Islands Dollar", symbol: "$" },
  { value: "SCR", label: "SCR — Seychellois Rupee", symbol: "₨" },
  { value: "SDG", label: "SDG — Sudanese Pound", symbol: "ج.س." },
  { value: "SEK", label: "SEK — Swedish Krona", symbol: "kr" },
  { value: "SGD", label: "SGD — Singapore Dollar", symbol: "$" },
  { value: "SHP", label: "SHP — Saint Helena Pound", symbol: "£" },
  { value: "SLL", label: "SLL — Sierra Leonean Leone", symbol: "Le" },
  { value: "SOS", label: "SOS — Somali Shilling", symbol: "Sh" },
  { value: "SRD", label: "SRD — Surinamese Dollar", symbol: "$" },
  { value: "STD", label: "STD — São Tomé and Príncipe Dobra", symbol: "Db" },
  { value: "SVC", label: "SVC — Salvadoran Colón", symbol: "$" },

  { value: "SYP", label: "SYP — Syrian Pound", symbol: "£" },
  { value: "SZL", label: "SZL — Swazi Lilangeni", symbol: "L" },
  { value: "THB", label: "THB — Thai Baht", symbol: "฿" },
  { value: "TJS", label: "TJS — Tajikistani Somoni", symbol: "ЅМ" },
  { value: "TMT", label: "TMT — Turkmenistani Manat", symbol: "m" },
  { value: "TND", label: "TND — Tunisian Dinar", symbol: "د.ت" },
  { value: "TOP", label: "TOP — Tongan Paʻanga", symbol: "T$" },
  { value: "TRY", label: "TRY — Turkish Lira", symbol: "₺" },
  { value: "TTD", label: "TTD — Trinidad and Tobago Dollar", symbol: "$" },
  { value: "TWD", label: "TWD — New Taiwan Dollar", symbol: "$" },
  { value: "TZS", label: "TZS — Tanzanian Shilling", symbol: "Sh" },
  { value: "UAH", label: "UAH — Ukrainian Hryvnia", symbol: "₴" },
  { value: "UGX", label: "UGX — Ugandan Shilling", symbol: "Sh" },
  { value: "USD", label: "USD — United States Dollar", symbol: "$" },
  { value: "UYU", label: "UYU — Uruguayan Peso", symbol: "$" },
  { value: "UZS", label: "UZS — Uzbekistan Som", symbol: "лв" },
  { value: "VEF", label: "VEF — Venezuelan Bolívar", symbol: "Bs" },
  { value: "VND", label: "VND — Vietnamese Đồng", symbol: "₫" },
  { value: "VUV", label: "VUV — Vanuatu Vatu", symbol: "Vt" },
  { value: "WST", label: "WST — Samoan Tala", symbol: "T" },
  { value: "XAF", label: "XAF — Central African Cfa Franc", symbol: "Fr" },
  { value: "XCD", label: "XCD — East Caribbean Dollar", symbol: "$" },
  { value: "XOF", label: "XOF — West African Cfa Franc", symbol: "Fr" },
  { value: "XPF", label: "XPF — Cfp Franc", symbol: "Fr" },
  { value: "YER", label: "YER — Yemeni Rial", symbol: "﷼" },
  { value: "ZAR", label: "ZAR — South African Rand", symbol: "R" },
  { value: "ZMW", label: "ZMW — Zambian Kwacha", symbol: "ZK" },
];
