import { useEffect, useState } from "react";
import { Button, EmptyState, PageTitle, TextInput } from "src/components";
import { PageLoader } from "src/components/page-loader";
import Axios from "src/services/api";
import { Count } from "../Customers/Customers";
import { Link, useNavigate } from "react-router-dom";
import styles from "./BoughtProducts.module.scss";
import { downloadFile } from "../Sales/CheckoutSuccess/utils";
import dayjs from "dayjs";
import { formatCurrency } from "src/services/currency";
import { makeCdnUrl } from "src/services/cdn";

const columns = ["Name", "Price", "Date bought", ""];

const BoughtProducts: React.FC = () => {
  const navigate = useNavigate();
  const [boughtProducts, setBoughtProducts] = useState<any[]>([]);
  const [filteredBoughtProducts, setFilteredBoughtProducts] = useState<any[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const [search, setSearch] = useState<string>("");

  useEffect(() => {
    Axios.get("/bought-products").then((response) => {
      setBoughtProducts(response.data);
      setLoading(false);

      if (response.data.length === 0) {
        // go to home page
        navigate("/");
      }
    });
  }, []);

  useEffect(() => {
    if (search === "") {
      setFilteredBoughtProducts(boughtProducts);
    } else {
      setFilteredBoughtProducts(
        boughtProducts.filter((product) =>
          product.product.name.includes(search)
        )
      );
    }
  }, [search, boughtProducts]);

  useEffect(() => {
    Axios.get("/bought-products").then((response) => {
      setBoughtProducts(response.data);
      setLoading(false);
    });
  }, []);

  if (loading) return <PageLoader />;

  return (
    <div className={styles.pageContainer}>
      <PageTitle
        actions={
          <div className={styles.searchInputContainer}>
            <div className={styles.seachInput}>
              <TextInput
                name="search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search products"
              />
            </div>
          </div>
        }
      >
        Products you bought
      </PageTitle>

      <>
        <div className={styles.tableContainer}>
          <table className={styles.productsTable}>
            <thead>
              <tr>
                {columns.map((column, index) => (
                  <th key={index}>{column}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {filteredBoughtProducts?.map((boughtProduct, index) => {
                const { product } = boughtProduct;
                return (
                  <tr
                    key={index}
                    onClick={() => {
                      if (product.isFile) {
                        downloadFile(product.file, product.name);
                      } else {
                        // window.open(product.url, "_blank");
                        const a = document.createElement("a");
                        a.href = product.url;
                        a.rel = "noopener noreferrer";
                        a.click();
                      }
                    }}
                  >
                    <td style={{ width: "50%" }}>
                      <div className={styles.productNameContainer}>
                        <img
                          src={
                            product.thumbnail
                              ? makeCdnUrl(product.thumbnail)
                              : "https://via.placeholder.com/600?text=Product+Image"
                          }
                        />
                        {product.name}
                      </div>
                    </td>
                    <td style={{ width: "10%" }}>
                      {formatCurrency(product.price)}
                    </td>
                    <td>
                      <div>{dayjs(boughtProduct.createdAt).fromNow()}</div>
                    </td>
                    <td>
                      <Button
                        text="Get product"
                        onClick={() => {
                          if (product.isFile) {
                            downloadFile(product.file, product.name);
                          } else {
                            // window.open(product.url, "_blank");
                            const a = document.createElement("a");
                            a.href = product.url;
                            a.rel = "noopener noreferrer";
                            a.click();
                          }
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <Count items={filteredBoughtProducts} />
      </>
    </div>
  );
};

export default BoughtProducts;
