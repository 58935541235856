import { useAuth0 } from "@auth0/auth0-react";
import React, {
  ChangeEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import PP from "./payp.png";
import { toast } from "react-hot-toast";
import lodash from "lodash";
import {
  Button,
  Card,
  ErrorBanner,
  FormGroup,
  PageTitle,
  Select,
  SwitchInput,
  TextInput,
} from "src/components";
import Axios from "src/services/api";
import styles from "./Profile.module.scss";
import { Link } from "react-router-dom";
import { formatCurrency } from "src/services/currency";
import { Creator } from "../Sales/Creator";

const CreatorSettings = () => {
  const me = useMemo(
    () => localStorage.getItem("me") && JSON.parse(localStorage.getItem("me")!),
    [localStorage.getItem("me")]
  );
  const [products, setProducts] = React.useState<any[]>([]);

  const defaultUserData = useMemo(
    () => ({
      tagLine: me?.tagLine || "",
      creatorProducts: me?.creatorProducts || [],

      twitter: me?.twitter || "",
      facebook: me?.facebook || "",
      instagram: me?.instagram || "",
      youtube: me?.youtube || "",
      tiktok: me?.tiktok || "",
      linkedin: me?.linkedin || "",
    }),
    [me]
  );

  const [userData, setUserData] = useState(defaultUserData);

  useEffect(() => {
    Axios.get("/products").then((res) => {
      setProducts(res.data);
    });
  }, []);

  const submitForm = (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    toast.promise(
      Axios.post(`/users/update`, userData).then((res) => {
        localStorage.setItem("me", JSON.stringify(res.data));
        window.location.reload();
      }),
      {
        loading: "Saving...",
        success: "Saved!",
        error: "Error while saving.",
      }
    );
  };

  return (
    <form onSubmit={submitForm}>
      <div className={styles.settingsPage}>
        <PageTitle
          actions={
            <>
              <Link
                target="_blank"
                to={`https://${me.subdomain}.${process.env.REACT_APP_AUTH0_AUDIENCE}`}
              >
                <Button text={"View page"} variant="tertiary" />
              </Link>
              <Button
                text={"Cancel"}
                variant="secondary"
                onClick={() => setUserData(defaultUserData)}
              />
              <Button
                text={"Save changes"}
                type="submit"
                disabled={lodash.isEqual(userData, defaultUserData)}
              />
            </>
          }
        >
          Creator Page
        </PageTitle>
        <Card>
          <div className={styles.bigContainer}>
            <div className={styles.formsContainer}>
              <FormGroup label="Enter a tag line – your description">
                <TextInput
                  name="tagLine"
                  value={userData.tagLine}
                  placeholder="Tag line"
                  onChange={(e) =>
                    setUserData({ ...userData, tagLine: e.target.value })
                  }
                />
              </FormGroup>
              <FormGroup label="Twitter">
                <TextInput
                  name="twitter"
                  value={userData.twitter}
                  placeholder="Enter your Twitter profile link"
                  onChange={(e) =>
                    setUserData({ ...userData, twitter: e.target.value })
                  }
                />
              </FormGroup>
              <FormGroup label="Facebook">
                <TextInput
                  name="facebook"
                  value={userData.facebook}
                  placeholder="Enter your Facebook profile link"
                  onChange={(e) =>
                    setUserData({ ...userData, facebook: e.target.value })
                  }
                />
              </FormGroup>
              <FormGroup label="Instagram">
                <TextInput
                  name="instagram"
                  value={userData.instagram}
                  placeholder="Enter your Instagram profile link"
                  onChange={(e) =>
                    setUserData({ ...userData, instagram: e.target.value })
                  }
                />
              </FormGroup>
              <FormGroup label="Youtube">
                <TextInput
                  name="youtube"
                  value={userData.youtube}
                  placeholder="Enter your Youtube profile link"
                  onChange={(e) =>
                    setUserData({ ...userData, youtube: e.target.value })
                  }
                />
              </FormGroup>
              <FormGroup label="Tiktok">
                <TextInput
                  name="tiktok"
                  value={userData.tiktok}
                  placeholder="Enter your Tiktok profile link"
                  onChange={(e) =>
                    setUserData({ ...userData, tiktok: e.target.value })
                  }
                />
              </FormGroup>
              <FormGroup label="Linkedin">
                <TextInput
                  name="linkedin"
                  value={userData.linkedin}
                  placeholder="Enter your Linkedin profile link"
                  onChange={(e) =>
                    setUserData({ ...userData, linkedin: e.target.value })
                  }
                />
              </FormGroup>
            </div>
            <div className={styles.prodPageContainer}>
              <Creator
                userId={me._id}
                _subdomain={me.subdomain}
                _user={{
                  ...me,
                  ...userData,
                }}
              />
            </div>
          </div>
        </Card>
      </div>
    </form>
  );
};

export default CreatorSettings;
