import React, { useEffect, useMemo, useState } from "react";
import styles from "./Payouts.module.scss";
import {
  Card,
  PageTitle,
  PageLayout,
  Button,
  InfoIcon,
  EmptyState,
} from "src/components";
import Axios from "src/services/api";
import { formatCurrency } from "src/services/currency";
import dayjs from "dayjs";
import usePopups from "src/services/usePopups";
import { PayoutSettings } from "./components/PayoutSettings";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { makeCdnUrl } from "src/services/cdn";
// import { toast } from "react-hot-toast";

// const columns = ["Name", "Gross", "Taxes", "Fees", "Commissions", "Net"];
const affiliatedColumns = ["Name", "Total sales", "Your Commissions"];

const Payouts: React.FC = () => {
  // const [balanceInUSD, setBalanceInUSD] = useState<any>(0);
  const navigate = useNavigate();
  const me = useMemo(() => JSON.parse(localStorage.getItem("me") || "{}"), []);
  const [availableBalanceInUSD, setAvailableBalanceInUSD] = useState<any>(0);
  const [pendingBalanceInUSD, setPendingBalanceInUSD] = useState<any>(0);
  const [earnings7Days, setEarnings7Days] = useState<any>(0);
  const [earningsAllTime, setEarningsAllTime] = useState<any>(0);
  const [payoutSchedule, setPayoutSchedule] = useState<any>("weekly");
  const [lastPayoutDate, setLastPayoutDate] = useState<any>(
    dayjs().subtract(1, "days").format()
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [lastPayoutAmount, setLastPayoutAmount] = useState<any>(0);
  const [canPayout, setCanPayout] = useState<boolean>(false);
  const [nextPayoutDate, setNextPayoutDate] = useState<any>(null);

  const [products, setProducts] = useState<any>([]);
  const [affiliatedProducts, setAffiliatedProducts] = useState<any>([]);
  const [nextPayoutAmount, setNextPayoutAmount] = useState<any>(0);

  const { popups, togglePopup } = usePopups(["payoutsSettings"]);
  const [key, setKey] = useState<number>(0);

  useEffect(() => {
    // get payouts
    if (me?.stripeConnectedAccountId) {
      Axios.get("/payouts").then((res) => {
        const { data } = res;
        setEarnings7Days(data.earnings7Days);
        // setBalanceInUSD(data.balance);
        setAvailableBalanceInUSD(data.availableBalanceInUSD);
        setPendingBalanceInUSD(data.pendingBalanceInUSD);
        setEarningsAllTime(data.earningsAllTime);
        setPayoutSchedule(data.payoutSchedule);
        setLastPayoutDate(data.lastPayoutDate);
        setLastPayoutAmount(data.lastPayoutAmount);
        setCanPayout(data.canPayout);
        setNextPayoutDate(data.nextPayoutDate);
        setNextPayoutAmount(data.nextPayoutAmount);
        return Axios.get("/payouts/products").then((res) => {
          setLoading(false);
          setProducts(res.data.productsWithDetails || []);
          setAffiliatedProducts(res.data.affiliatedProductsWithDetails || []);
        });
      });
    } else {
      setLoading(false);
    }
  }, [key, me]);

  const handlePayoutNowClick = () => {
    Axios.post("/payouts/now").then((res) => {
      console.log(res.data);
    });
  };

  const [stripeAccount, setStripeAccount] = React.useState<any>(null);

  useEffect(() => {
    Axios.get("/users/stripe-account").then((res) => {
      setStripeAccount(res.data);
    });
  }, []);

  const createLogin = async () => {
    try {
      toast.promise(
        Axios.get("/users/create-login-link").then((response) => {
          // window.open(response.data.url, "_blank");
          const a = document.createElement("a");
          a.href = response.data.url;
          a.rel = "noopener noreferrer";
          a.click();
        }),
        {
          loading: "Loading...",
          success: "Loaded!",
          error: "Error while loading.",
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles.pageContainer}>
      <PageTitle
        actions={
          <>
            {loading || !me?.stripeConnectedAccountId ? null : (
              <>
                {payoutSchedule !== "manual" && (
                  <InfoIcon
                    text={
                      <>
                        You can only trigger a payout when
                        <br />
                        you have a payout schedule set to manual
                        <br />
                        in your Stripe account.
                      </>
                    }
                  />
                )}
                {payoutSchedule === "manual" && !canPayout && (
                  <InfoIcon
                    text="You have manual payout enabled, but you can't trigger a payout
                  until money is available in your Stripe account."
                  />
                )}

                <Button
                  disabled={payoutSchedule !== "manual" || !canPayout}
                  text="Payout now"
                  variant="tertiary"
                  onClick={handlePayoutNowClick}
                />
                <Button
                  text="Payout settings"
                  onClick={() => togglePopup("payoutsSettings")}
                  variant="secondary"
                />
                <div className={styles.divider} />
                <Button text="Manage bank account" onClick={createLogin} />
              </>
            )}
          </>
        }
      >
        Payouts
        <span className={styles.onHold}>Bank account only</span>
      </PageTitle>
      {loading || (!me?.stripeConnectedAccountId && !me?.merchantIdInPayPal) ? (
        <EmptyState
          loading={loading}
          title="Connect your Bank Account or PayPal"
          subTitle="You need to connect your Bank Account or PayPal to see your payouts information."
          buttonText="Connect Payment Method"
          buttonClick={() => {
            navigate("/profile/payments");
          }}
        />
      ) : (
        <>
          <div className={styles.topGrid}>
            <Card>
              <div className={styles.cardTitle}>
                Available balance{" "}
                <InfoIcon text="This is the money that is available to be paid out." />
              </div>
              <div className={styles.cardValue}>
                {formatCurrency(availableBalanceInUSD)}
              </div>
            </Card>
            <Card>
              <div className={styles.cardTitle}>
                Pending balance{" "}
                <InfoIcon text="This money is still in the pending phase. it can be paid out when it becomes available." />
              </div>
              <div className={styles.cardValue}>
                {formatCurrency(
                  pendingBalanceInUSD,
                  stripeAccount?.default_currency?.toUpperCase()
                )}
              </div>
            </Card>
            {/* <Card>
              <div className={styles.cardTitle}>All-time profit</div>
              <div className={styles.cardValue}>
                {formatCurrency(earningsAllTime)}
              </div>
            </Card> */}
            <Card>
              <div className={styles.cardTitle}>
                Last payout amount{" "}
                <InfoIcon text="This payout was made with your bank account's currency" />
              </div>
              <div className={styles.cardValue}>
                {formatCurrency(
                  lastPayoutAmount,
                  stripeAccount?.default_currency?.toUpperCase()
                )}
              </div>
            </Card>
            <Card>
              <div className={styles.cardTitle}>Last payout</div>
              <div className={styles.cardValue}>
                {lastPayoutDate ? dayjs(lastPayoutDate).fromNow() : "Never"}
              </div>
            </Card>
            <Card>
              <div className={styles.cardTitle}>Payout frequency</div>
              <div className={styles.cardValue}>
                {payoutSchedule === "weekly"
                  ? "Weekly"
                  : payoutSchedule === "daily"
                  ? "Daily"
                  : payoutSchedule === "monthly"
                  ? "Monthly"
                  : "Manual"}
              </div>
            </Card>
          </div>

          {/* {products.length > 0 && (
            <div className={styles.innerPageSection}>
              <h2 className={styles.innerTitle}>Balance per product</h2>
              <div className={styles.tableContainer}>
                <table className={styles.productsTable}>
                  <thead>
                    <tr>
                      {columns.map((column, index) => (
                        <th key={index}>{column}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {products.map((product: any, index: any) => (
                      <tr key={index}>
                        <td style={{ width: "35%" }}>
                          <Link
                            className={styles.productNameContainer}
                            to={`/products/${product.productId}`}
                          >
                            <img
                              src={
                                product.thumbnail ||
                                "https://via.placeholder.com/600?text=Product+Image"
                              }
                            />
                            {product.name}
                          </Link>
                        </td>
                        <td>{formatCurrency(product.totalSales)}</td>
                        <td>{formatCurrency(product.totalTaxAmount)}</td>
                        <td>{formatCurrency(0)}</td>
                        <td>- {formatCurrency(product.totalAffiliateCut)}</td>
                        <td>
                          {formatCurrency(
                            product.totalAmountBeforeTax -
                              product.totalAffiliateCut
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )} */}

          {affiliatedProducts.length > 0 && (
            <div className={styles.innerPageSection}>
              <h2 className={styles.innerTitle}>
                Balance per affiliated product
              </h2>
              <div className={styles.tableContainer}>
                <table className={styles.productsTable}>
                  <thead>
                    <tr>
                      {affiliatedColumns.map((column, index) => (
                        <th key={index}>{column}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {affiliatedProducts.map((product: any, index: any) => (
                      <tr key={index}>
                        <td style={{ width: "35%" }}>
                          <Link
                            className={styles.productNameContainer}
                            to={`/products/${product.productId}`}
                          >
                            <img
                              src={
                                product.thumbnail
                                  ? makeCdnUrl(product.thumbnail)
                                  : "https://via.placeholder.com/600?text=Product+Image"
                              }
                            />
                            {product.name}
                          </Link>
                        </td>
                        <td>{formatCurrency(product.totalAmount / 100)}</td>
                        <td>{formatCurrency(product.totalAffiliateCut)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}

          <div className={styles.innerPageSection}>
            <h2 className={styles.innerTitle}>
              Next payout:{" "}
              {nextPayoutDate && dayjs(nextPayoutDate).format("MMMM D, YYYY")}{" "}
              <InfoIcon text="Only the available balance will be paid out." />
            </h2>
            <Card>
              {/* <div className={styles.detail}>
                <div className={styles.label}>Sales</div>
                <div className={styles.value}>
                  {formatCurrency(nextPayoutAmount)}
                </div>
              </div> */}
              {/* <div className={styles.detail}>
                <div className={styles.label}>Fees</div>
                <div className={styles.value}>- {formatCurrency(0)}</div>
              </div> */}
              {/* <div className={styles.detail}>
            <div className={styles.label}>Affiliate Commissions</div>
            <div className={styles.value}>- $0.00</div>
          </div> */}
              <div className={styles.detail}>
                <div className={styles.label}>Will be sent to you</div>
                <div className={styles.value}>
                  {formatCurrency(availableBalanceInUSD)}
                </div>
              </div>
            </Card>
          </div>
        </>
      )}
      {popups.payoutsSettings && (
        <PayoutSettings
          initialValue={payoutSchedule}
          onClose={() => {
            setKey(Math.random());
            togglePopup("payoutsSettings");
          }}
        />
      )}
    </div>
  );
};

export default Payouts;

const fetchExchangeRate = async (
  fromCurrency: string,
  toCurrency: string = "USD"
) => {
  // replace this URL with the API you're using, this is just a placeholder
  const response = await fetch(
    `https://api.exchangerate-api.com/v4/latest/${fromCurrency}`
  );
  const rates = await response.json();

  return rates.rates[toCurrency];
};

const convertToUSD = async (amount: number, fromCurrency: string) => {
  const rate = await fetchExchangeRate(fromCurrency);

  return amount * rate;
};

const displayBalance = async (balances: any[]) => {
  let totalBalanceInUSD = 0;

  for (let balance of balances) {
    const amountInUSD = await convertToUSD(
      balance.amount / 100,
      balance.currency
    );
    totalBalanceInUSD += amountInUSD;
  }

  return totalBalanceInUSD.toFixed(2); // round to 2 decimal places
};
